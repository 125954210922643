import './DrinkingReasons.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DrinkingReasons() {
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="DrinkingReasons" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="DrinkingReasons-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Reasons-Progress-Bar'>
      <div className='Reasons-Progress-Indicator'></div>
      <div className='Reasons-Progress-Indicator-Gradient'></div>
        <div className='Reasons-Name-Icon-Div'>
        
            <img className='Reasons-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Reasons-Flag-Icon-Div'>
        
            <img className='Reasons-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Reasons-Pin-Icon-Div'>
        
            <img className='Reasons-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Reasons-Brain-Icon-Div'>
        
            <img className='Reasons-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Reasons-Check-Icon-Div'>
        
            <img className='Reasons-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
    
      <a className='Reasons-Question-Text'>
      {global.name}, what were some of the reasons you used to drink?      </a>
      <div className='Reasons-Main-Div'>
      <div className='Relax-Div'>
        <a className='Relax-Text'>
        To relax
        </a>
      </div>
      <div className='Fun-Div'>
        <a className='Fun-Text'>
        To have fun / socialize      
     </a>
      </div>
      <div className='Stress-Div'>
        <a className='Stress-Text'>
        To escape stress or worry      
     </a>
      </div>
      <div className='Bored-Div'>
        <a className='Bored-Text'>
        When I’m bored      
     </a>
      </div>
      
      <div className='Upset-Div'>
        <a className='Upset-Text'>
        When I’m upset      
     </a>
      </div>
      
      <div className='Angry-Div'>
        <a className='Angry-Text'>
        When I’m angry      
     </a>
      </div>
      
      <div className='Others-Div'>
        <a className='Others-Text'>
        Others      
     </a>
      </div>
      </div>
         
    </motion.div>
    </motion.div>
  );
}

export default DrinkingReasons;
