import './DoctorScreen.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="DoctorScreen" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="Doctor-header">
      
        
      </header>
      
      
    <div className='Doctors-Div'>
      
      <div className='Mark-Info-Div'>
      <a className='Mark-Name'>
      Dr.Mark Rapaport</a>
      <a className='Mark-Title'>
      Chairman
      </a>
      <a className='Mark-Position'>
      Psychiatry Dept., 
Emory University       
      </a>

      
      <div className='Mark-Img' ></div>
      </div>

      <div className='Michael-Div'>
      <div className='Michael-Info-Div'>
      <a className='Michael-Name'>
      Dr. Michael Epstein</a>
      <a className='Michael-Title'>
      Psychiatrist
      </a>
      <a className='Michael-Position'>
      Psychiatry Dept., 
Emory University       
      </a>

      </div>
      <div className='Michael-Img' ></div>
      </div>


      <div className='Frank-Div'>
      <div className='Frank-Info-Div'>
      <a className='Frank-Name'>
      Dr. Frank Brown</a>
      <a className='Frank-Title'>
      Chief Quality Officer
      </a>
      <a className='Frank-Position'>
      Emory University Hospital       
      </a>

      </div>
      <div className='Frank-Img' ></div>
      </div>
      </div>
      <p className='neuroscience'>Reframe utilizes the power of <b>neuroscience</b> to literally <b>change</b> our brains.<br></br>
      <br></br>
      We focus on <b>education, introducing new ides, frameworks and theories.</b>Doing it all through <b>evidence-based</b> strategies that make it stick.</p>
      
     
      
     
      <Link to ='/transition' replace= {true}>
      <motion.div type='button' className='Doctor-Ready-Button'   >
      <a className='Doctor-Ready-Text'>
            Next
          </a>
      </motion.div>
      

    </Link>
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;
