import './NameScreen.css';
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Flag-Icon.jpg';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { useSearchParams } from 'react-router-dom';
import ReframeLogo from './Horizontal_Logo_Smaller.png';


import { Navigate, useNavigate } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';

import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function NameScreen() {
  /*
  useEffect(() => {
    // Update the document title using the browser API
    document.getElementsByClassName('Welcome-Popup')[0]; 
  });*/
 
  async function setUserID (){
    const jsonData  = {
      "functionType" : "createUser",
      "messageProperties": {
        "url": window.location.href,
        "path": window.location.pathname, 
        "host" : window.location.host, 
        "title": document.title, 
        "query": window.location.search,
        "referer": document.referrer},
      "context": {"userAgent":  navigator.userAgent}
    };
    await fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => storeUserID(data["userId"]));
  }
  function storeUserID(tosave){
    localStorage.setItem("UserID",tosave)
    console.log('this is the userid: ', localStorage.getItem("UserID"))

  }

  useEffect(() => {
    console.log('this is the user agent: ', navigator.userAgent.includes('Android'));
    if(navigator.userAgent.includes('Android')){
      document.getElementsByClassName('Ios-Only-Popup')[0].style.visibility = "visible"
    }
    //Get the uuid here and store it locally
    //localStorage.setItem("UUID", );
    console.log('this is the userid before: ', localStorage.getItem("UserID"))
     console.log('this is the firsttime: ', localStorage.getItem("firstTime", "true"));
    if(localStorage.getItem("firstTime") === null){
     
      console.log('it got here to calling the createfunction,')
      setUserID();
      
    }else{
    
    }
    localStorage.setItem("firstTime", "false");
  });

  const [searchParams, setSearchParams] = useSearchParams();
var emailFromParent = searchParams.get("inputedByParent")
console.log('this is the parent email: ', emailFromParent);
localStorage.setItem("inputedByParent", emailFromParent);
  const navigate = useNavigate();
  function saveName () {
    var nameInput = document.getElementsByClassName('First-Name')[0].value
    console.log("this is the name: ", nameInput)
    if(nameInput == null){
      nameInput = "";
    }
    var firsttime;
    console.log('this is the name: ' , localStorage.getItem("name"));
    if(localStorage.getItem("name",  null)== null){
      firsttime = "true"
    }else{
      firsttime= "false"
    }
    
    localStorage.setItem("name", nameInput);
    global.name  = nameInput;
    console.log('this is the userid before sending: ', localStorage.getItem("UserID"));
    const jsonData  = {
      "functionType" : "updateUser",
      "attributeType": "name",
      "value": nameInput,
      "firstTime": firsttime,
      "userId": localStorage.getItem("UserID"),
      "messageProperties": {
        "url": window.location.href,
        "path": window.location.pathname, 
        "host" : window.location.host, 
        "title": document.title, 
        "query": window.location.search,
        "referer": document.referrer},
      "context": {"userAgent":  navigator.userAgent}

    };
    console.log('thi si sthe body that was sent: ', jsonData);
    fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
    
    navigate("/alcoholreduction");
    
  }
  function closePopup(){
    document.getElementsByClassName('Welcome-Popup')[0].style.visibility ='hidden';
    document.getElementsByClassName('Ios-Only-Popup')[0].style.visibility ='hidden';

  }
  console.log('this is the platform: ', window.navigator.platform);
    console.log('Transition')
  return (
    <motion.div>

    <motion.div className="NameScreen" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>
    <div className="Welcome-Popup" >
  
     <div className='Popup-Blur-Div'></div>

      <div className='Popup-Div'>
      <div className='Ios-Only-Popup'>
      <a className='Ios-Only-Text'>
      Reframe is iOS-only
      </a>
      </div>
      <div className='Referal-Discount-Div'>
      <div className='Cancel-Icon-Div' onClick = {closePopup}></div>
      <a className='You-Got-Text'>You Got a</a>
      <a className='Referal-Discount-Text'>
      Referral Discount!
      </a>
      <div className='Confetti-Div'>
      </div>
      </div>
      <div className='Special-Offer-Div'>
      <a className='Special-Offer-Text'>Special offer just for you! </a>
      <a className='Popup-Free-Trial-Text'>
      14-day free trial
      </a>
      <div className='Fireworks-Div'>
      </div>
      <div className='Claim-Now-Button' onClick = {closePopup}>
      <a className='Claim-Now-Text'>
      CLAIM MY OFFER NOW
      </a>
      </div>
      </div>

      </div>
    </div>

      <img className="Name-header" src={ReframeLogo}>
      
        
      </img>
  
      <div className='Name-Main-Div'>

      <p className='Welcome'>
      Welcome! First, we’d love to get to know you a little better...</p>

      <a className='Call-You'>
      What should we call you?
      </a>
      
      
      

      
      <input type="text" name="" id="name" className='First-Name' placeholder='First Name *'/>

     
      </div>
      
      
     
      
     
      
   
      <motion.div type='button' className='Name-Ready-Button' onClick={saveName}   >
      <a className='Name-Ready-Text'>
      Next
    </a>
      </motion.div>
     
  

   
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default NameScreen;
