const Cognito = require("@aws-sdk/client-cognito-identity-provider");

const clientId = '7qbgnouhu1ir0ekltdus10a0j6'
const region = 'us-east-1'



/*exports.handler = async (event, context) => {
  if (event.httpMethod !== 'POST' || !event.body) {
    return { statusCode: 400, body: 'Invalid request' };
  }
  const cognitoClient = new Cognito.CognitoIdentityProviderClient({ region });
  const authenticationData = JSON.parse(event.body);

  let response;
  if (authenticationData.type === 'signup') {
    response = await signUp(cognitoClient, authenticationData);
  } else if (authenticationData.type === 'login') {
    response = await login(cognitoClient, authenticationData);
  } else if (authenticationData.type === 'confirm') {
    response = await confirmCode(cognitoClient, authenticationData);
  } else if (authenticationData.type === 'resendCode') {
    response = await resendCode(cognitoClient, authenticationData);
  } else if (authenticationData.type === 'getUserId') {
    response = await getUserId(cognitoClient, authenticationData);
  } else {
    return { statusCode: 400, body: 'Invalid Request' };
  }

  if (response["$metadata"].httpStatusCode !== 200) {
    const errorBody = JSON.stringify({"error": {
      "message": response.message,
      "name": response.name,
    }});

    return { statusCode: response["$metadata"].httpStatusCode, body: errorBody };
  }

  if (authenticationData.type === 'getUserId') {
    const userId = response.Username;
    const body = JSON.stringify({ "userId": userId });
    return { statusCode: 200, body: body };
  }

  return { statusCode: response["$metadata"].httpStatusCode, body: JSON.stringify(response["$metadata"]) };
};*/

export async function signUp(cognitoClient, authenticationData) {
  try {
    const response = await cognitoClient.send(new Cognito.SignUpCommand({
      Username: authenticationData.email.toLowerCase(),
      Password: authenticationData.password,
      ClientId: clientId,
      UserAttributes: [
        { "Name": "custom:programTrack", "Value": authenticationData.programTrack },
      ]
    }));
    return response;
  } catch (err) {
    return err;
  }
}

export async function confirmCode(cognitoClient, authenticationData) {
  try {
    const response = await cognitoClient.send(new Cognito.ConfirmSignUpCommand({
      Username: authenticationData.email.toLowerCase(),
      ClientId: clientId,
      ConfirmationCode: authenticationData.code
    }));

    return response;
  } catch (err) {
    return err;
  }
}

export async function resendCode(cognitoClient, authenticationData) {
  try {
    const response = await cognitoClient.send(new Cognito.ResendConfirmationCodeCommand({
      Username: authenticationData.email.toLowerCase(),
      ClientId: clientId
    }));

    return response;
  } catch (err) {
    return err;
  }
}

export async function login(cognitoClient, authenticationData) {
  try {
    const response = await cognitoClient.send(new Cognito.InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: authenticationData.email.toLowerCase(),
        PASSWORD: authenticationData.password
      },
      ClientId: clientId
    }));

    return response;
  } catch (err) {
    return err;
  }
}

 export async function getUserId(cognitoClient, authenticationData) {
  try {
    const loginResponse = await login(cognitoClient, authenticationData);

    const accessToken = loginResponse.AuthenticationResult.AccessToken;

    const getUserResponse = await cognitoClient.send(new Cognito.GetUserCommand({
      AccessToken: accessToken
    }));

    console.log(getUserResponse);

    return getUserResponse;
  } catch (err) {
    return err;
  }
}