import './AgeRange.css';
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Flag-Icon.jpg';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import ReframeLogo from './Horizontal_Logo_Smaller.png';



import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
  
  console.log('this is the age: ' , localStorage.getItem("age"));
  var previoslySelectedButton = "Eightteen-Div", previouslySelectedText = "Eightteen-Text";
  useEffect(() => {
    // Update the document title using the browser API
    
    if(!(localStorage.getItem("age") == null)){
      var buttonName, textName;
        switch(localStorage.getItem("age")){
          case "18-25":
            buttonName ="Eightteen-Div";
            textName = "Eightteen-Text";
            break;
          case "26-30":
            buttonName ="Twenty-Six-Div";
            textName = "Twenty-Six-Text";
            break;
          case "31-40":
            buttonName ="Thirty-One-Div";
            textName = "Thirty-One-Text";
            break;
          case "41-50":
            buttonName ="Fourty-One-Div";
            textName = "Fourty-One-Text";
            break;
          case "51-55":
            buttonName ="Fifty-One-Div";
            textName = "Fifty-One-Text";
            break;
          case "55+":
            buttonName ="Fifty-Five-Div";
            textName = "Fifty-Five-Text";
            break;
  

        }
        previoslySelectedButton = buttonName;
        previouslySelectedText = textName;
        var buttonElement = document.getElementsByClassName(buttonName)[0];
        buttonElement.style.background = "#213088";
        var buttonTextElement = document.getElementsByClassName(textName)[0];
        buttonTextElement.style.color = "#fff";
    }
  });
  const navigate = useNavigate();
    function toNextPage (tosave ){
      global.age = tosave;
      var firsttime;
      if(localStorage.getItem("age",  null)== null){
        firsttime = "true"
      }else{
        firsttime= "false"
      }
      localStorage.setItem("age", tosave)
      var buttonName, textName;
      switch(tosave){
        case "18-25":
          buttonName ="Eightteen-Div";
          textName = "Eightteen-Text";
          break;
        case "26-30":
          buttonName ="Twenty-Six-Div";
          textName = "Twenty-Six-Text";
          break;
        case "31-40":
          buttonName ="Thirty-One-Div";
          textName = "Thirty-One-Text";
          break;
        case "41-50":
          buttonName ="Fourty-One-Div";
          textName = "Fourty-One-Text";
          break;
        case "51-55":
          buttonName ="Fifty-One-Div";
          textName = "Fifty-One-Text";
          break;
        case "55+":
          buttonName ="Fifty-Five-Div";
          textName = "Fifty-Five-Text";
          break;

  
      }
      var previousButtonElement = document.getElementsByClassName(previoslySelectedButton)[0];
      previousButtonElement.style.background = "#fff";
      var previousButtonTextElement = document.getElementsByClassName(previouslySelectedText)[0];
      previousButtonTextElement.style.color = "#213088";
      var buttonElement = document.getElementsByClassName(buttonName)[0];
      buttonElement.style.background = "#213088";
      var buttonTextElement = document.getElementsByClassName(textName)[0];
      buttonTextElement.style.color = "#fff";
      console.log('this is the userID before: ', localStorage.getItem("UserID"))
      const jsonData  = {
        "functionType" : "updateUser",
        "attributeType": "agerange",
        "value": tosave,
        "firstTime": firsttime,
        "userId": localStorage.getItem("UserID"),
        "messageProperties": {
          "url": window.location.href,
          "path": window.location.pathname, 
          "host" : window.location.host, 
          "title": document.title, 
          "query": window.location.search,
          "referer": document.referrer},
        "context": {"userAgent":  navigator.userAgent}
  
      };
      console.log('this is the jsondata: ', jsonData);
      fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
    
      setTimeout(() => {
      navigate('/typicalWeek')
      }, 750);
      

    }
    function goBack (){
      navigate('/alcoholReduction')
    }
    console.log('Transition')
  return (
    <motion.div >
    
    <motion.div className="AgeRange" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="AgeRange-header" src={ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick = {goBack}>
      </img>
      
    
      <a className='Age-Text'>
      What is your age range?      </a>
      <div className='Age-Main-Div'>
      <div className='Eightteen-Div' onClick={() => toNextPage("18-25")}>
        <a className='Eightteen-Text'>
        18-25
        </a>
      </div>
      <div className='Twenty-Six-Div' onClick={() => toNextPage("26-30")}>
        <a className='Twenty-Six-Text'>
        26-30      
     </a>
      </div>
      <div className='Thirty-One-Div' onClick={ () => toNextPage("31-40")}>
        <a className='Thirty-One-Text'>
        31-40
                </a>
      </div>
      <div className='Fourty-One-Div' onClick={() => toNextPage("41-50")}>
        <a className='Fourty-One-Text'>
        41-50
        </a>
      </div>
      <div className='Fifty-One-Div' onClick={() => toNextPage("51-55")}>
        <a className='Fifty-One-Text'>
        51-55        </a>
      </div>
      <div className='Fifty-Five-Div' onClick={() => toNextPage("55+")}>
      <a className='Fifty-Five-Text'>
      55+
      </a>
    </div>
      </div>
     
    </motion.div>
  

    </motion.div>
  );
}

export default DoctorScreen;
