import './HearAbout.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Flag-Icon.jpg';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
  const navigate = useNavigate();
    function toNextPage (tosave){
      console.log('To Next page', tosave);
      global.hearabout = tosave;
      navigate('/identity')
      
    }
  
  return (
    <motion.div>
    
    <motion.div className="HearAbout" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="HearAbout-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Hear-Progress-Bar'>
      <div className='Hear-Progress-Indicator'></div>
      <div className='Hear-Progress-Indicator-Gradient'></div>
        <div className='Hear-Name-Icon-Div'>
        
            <img className='Hear-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Hear-Flag-Icon-Div'>
        
            <img className='Hear-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Hear-Pin-Icon-Div'>
        
            <img className='Hear-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Hear-Brain-Icon-Div'>
        
            <img className='Hear-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Hear-Check-Icon-Div'>
        
            <img className='Hear-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
      <a className='How-Hear'>
      How did you hear about us?
      </a>
      <div className='Hear-Main-Div'>
      <div className='Ads-Div' onClick={toNextPage("Ads")}>
        <a className='Ads-Text'>
        Online ads
        </a>
      </div>
      <div className='News-Div'  onClick={toNextPage("News")}>
        <a className='News-Text'>
        News, article, blog      
     </a>
      </div>
      <div className='Web-Div'  onClick={toNextPage("Web")}>
        <a className='Web-Text'>
        Web search
                </a>
      </div>
      <div className='TikTok-Div'  onClick={toNextPage("TikTok")}>
        <a className='TikTok-Text'>
        Tiktok
        </a>
      </div>
      <div className='Insta-Div'  onClick={toNextPage("Insta")}>
        <a className='Insta-Text'>
        Instagram
        </a>
      </div>
      <div className='Facebook-Div' onClick={toNextPage("Facebook")}>
        <a className='Facebook-Text'>
        Facebook
        </a>
      </div>
      <div className='App-Div'  onClick={toNextPage("App Store")}>
        <a className='App-Text'>
        App Store
        </a>
      </div>
      <div className='Friends-Div'  onClick={toNextPage("Friends")}>
        <a className='Friends-Text'>
        Friends/family
        </a>
      </div>
      <div className='Other-Div'>
      <a className='Other-Text'>
      Other:
      </a>
      <input type='text' className='Other-Input'></input>
      </div>
      
      
      </div>
     
      
     
      
     
      
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;
