import './AllDone.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function AllDone() {
    console.log('Transition')
  return (
   
    
    <motion.div className="AllDone" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="AllDone-header">
      
        
      </header>
   
      <div className='All-Done-Div'>
      
      <a className='All-Done-Text'>
      You’re all done!
      </a>
      <div className='Celebration-Emoji'>
      </div>
      </div>
      <a className='Complete-Set-Up-Text'>
      Next steps to complete your Reframe sign up
      </a>
      <p className='Next-Steps'>
      1. Download the Reframe app from the <span>App Store</span>
      <br></br>
      <br></br>
      2. <span>Log in</span> using the account you just created!
      </p>
      
      
    
      
    </motion.div>
  );
}

export default AllDone;
