import './CustomPlan.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Completed-Brain-Icon.svg';
import Check from './Check-Icon.svg';
import ReframeLogo from './Horizontal_Logo_Smaller.png';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function CustomPlan() {
  var monthlyCost, yearlyCost, monthlyID, yearlyID, monthlyCostYearlyPlan;
  var customPlanDisplayText = "";

  switch(localStorage.getItem("reductionPath")){
    case 'cutBack':
      customPlanDisplayText = "Cut Back"
      break;
    case 'quitDrinking':
      customPlanDisplayText = "Quit Drinking"
      break;
    case 'sober':
      customPlanDisplayText = ""
      break;
    case 'notSuder':
      customPlanDisplayText = ""
      break;
    default :
    customPlanDisplayText = ""
    break;
  }

  var planSelected = 'Yearly';
  if(localStorage.getItem("pricePerWeek") < 70 ){
      monthlyCost = 13.99;
      monthlyID = "price_1Jr8bwD12Dp8zl9wLJG1V4fv";
      yearlyCost = 79.99;
      yearlyID = "price_1JX9AjD12Dp8zl9wIGX3Fu15";
      monthlyCostYearlyPlan = 6.66;
  }else{
    monthlyCost = 24.99;
    monthlyID = "price_1Jr8crD12Dp8zl9wf3ncRCop";
    yearlyCost = 119.99;
    yearlyID = "price_1JUHhoD12Dp8zl9wyyO4wGAG";
    monthlyCostYearlyPlan = 9.99;
  }
  var planSelectedId= yearlyID;



  function openStripe(linkAddress){
    console.log('this is the data: ', linkAddress )
    window.open(linkAddress, '_self')
  }

  

  function toSalesPage(){
  
    const jsonData =
    {"inputedByChild":localStorage.getItem("email"), 
    "inputedByParent":localStorage.getItem("inputedByParent"), 
    "userId":localStorage.getItem("UserID"),
    "firstTime": "true",
    "plan_priceId":planSelectedId, "messageProperties": {
      "url": window.location.href,
      "path": window.location.pathname, 
      "host" : window.location.host, 
      "title": document.title, 
      "query": window.location.search,
      "referer": document.referrer},
    "context": {"userAgent":  navigator.userAgent}}
    
    //Email and price tag
    fetch('https://6mmjm733dvpbltpryfxxqofq240mxrfb.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => openStripe( data['checkoutSessionUrl']))
  }
  
function cancelPlan(){
  var DisclamerElement = document.getElementsByClassName('Fair-Trial-Div')[0];
  DisclamerElement.style.visibility = 'hidden';
}

  function montlyClicked ()  {
    
    planSelected = 'Monthly';
    var monthlyElement = document.getElementsByClassName('Monthly-Div')[0];
    monthlyElement.style.boxShadow = "0 0 10px 2px rgba(0, 0, 0, 0.25)";
    monthlyElement.style.border = "2px solid #018ABE";

    
    var yearlyElement = document.getElementsByClassName('Yearly-Div')[0];
    yearlyElement.style.boxShadow = "0 0 0px 0px #fff";
    yearlyElement.style.border = "0px solid #018ABE";

    var saveElement = document.getElementsByClassName('Yearly-Percent-Off-Div')[0];
    saveElement.style.backgroundColor = "#c4c4c4";

    var renewalElement = document.getElementsByClassName('Automatic-Renewal-Text')[0]
    renewalElement.textContent = "*After your free trial, the monthly subscription is " + monthlyCost + " USD and automatically renews each month. "
  }
  function yearlyClicked ()  {
   
    planSelected = 'Yearly';
    var monthlyElement = document.getElementsByClassName('Monthly-Div')[0];
    var yearlyElement = document.getElementsByClassName('Yearly-Div')[0];
    yearlyElement.style.boxShadow = "0 0 10px 2px rgba(0, 0, 0, 0.25)";
    yearlyElement.style.border = "2px solid #018ABE";

    
    
    monthlyElement.style.boxShadow = "0 0 0px 0px #fff";
    monthlyElement.style.border = "0px solid #018ABE";

    var saveElement = document.getElementsByClassName('Yearly-Percent-Off-Div')[0];
    saveElement.style.backgroundColor = "#018ABE";
    var renewalElement = document.getElementsByClassName('Automatic-Renewal-Text')[0]
    renewalElement.textContent = "*After your free trial, the yearly subscription is " + yearlyCost + " USD and automatically renews each year. "
  }
  function StartTrialClicked () {
    var DisclamerElement = document.getElementsByClassName('Fair-Trial-Div')[0];
    DisclamerElement.style.visibility = 'visible';
    if(planSelected === 'Yearly'){
      planSelectedId = yearlyID;
    }else{
      planSelectedId = monthlyID;
    }
    console.log('this is the selected plan id: ', planSelectedId)
    //can potentially get the stripe link from here instead of hacing the delay later
  }
  
  return (
    <motion.div>
    
    <motion.div className="CustomPlan" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="CustomPlan-header" src={ReframeLogo}>
      
        
      </img>
      <a className='Custom-Reframe-Plan-Text'>
      {localStorage.getItem("name")}’s Custom {customPlanDisplayText} Reframe Program      </a>

      <a className='How-Get-There-Text'>
      How you’ll get there
      </a>

      <div className='Checklist-Div'>
        <div className='Neuroscience-Based-Image'>
        </div>
        <a className='Neuroscience-Based-Text'>
        <b>Neuroscience-based</b> behavior change program
        </a>
        <div className='Daily-Based-Image'>
        </div>
        <a className='Daily-Based-Text'>
        <b>Daily exercises</b> to build great habits
        </a>
        <div className='Tools-Based-Image'>
        </div>
        <a className='Tools-Based-Text'>
        Evidence based tools, <b>meditations, breathing exercises</b> & more
        </a>
        <div className='Community-Based-Image'>
        </div>
        <a className='Community-Based-Text'>
        A <b>supportive community</b> to inspire & answer questions
        </a>
        <div className='Coach-Based-Image'>
        </div>
        <a className='Coach-Based-Text'>
        A <b>Thrive Coach</b> to provide you with additional support        </a>
      </div>
      <a className='Youll-Save-Approximately'>
      You’ll save approximately:
      </a>
      
      <a className='Amount-Saved-Text'>
      ${52* localStorage.getItem("pricePerWeek")}
      </a>
      <a className='Per-Year-Saving-Text'>
      per year
      </a>
      <a className='Our-Promise-Text'>
      Our promise is simple. You will see results or <b>100%</b> money back
      </a>

      <div className='Monthly-Div' onClick={montlyClicked}>
      <a className='Free-Trial-Text-Monthly'>
      Free 14 days Trial
      </a>
      <a className='Monthly-Price-Now'>
      ${monthlyCost}
      </a>
      <a className='Monthly-Price-Per-Month'>
      ${monthlyCost}/month
      </a>
      <a className='Monthly-Text'>
      Monthly
      </a>
      </div>
      
      <div className='Yearly-Div' onClick={yearlyClicked}>
      <a className='Free-Trial-Text-Yearly'>
      Free 14 days Trial
      </a>
      <a className='Yearly-Price-Now'>
      ${yearlyCost}
      </a>
      <a className='Yearly-Price-Per-Month'>
      ${monthlyCostYearlyPlan}/month
      </a>
      <a className='Yearly-Text'>
      Yearly
      </a>
      <div className='Yearly-Percent-Off-Div'>
        <a className='Yearly-Percent-Off-Text'>
        save 33%
        </a>
        
      </div>
      </div>
      <div className='Fair-Trial-Div'>
      <div className='Cancel-Plan-Div' onClick  = {cancelPlan}></div>
      <a className='Fair-Trial-Disclaimer'>Fair Trial Disclamer</a>
      <p className='Ten-Minutes-Text'>We only ask you to spend at least <b>10 minutes</b> a day using Reframe.</p>
      <p className='Cancel-Program-Text'>If you don’t, maybe this isn’t the best time for you to try Reframe <b>so you should cancel the program before your trial ends.</b></p>
      <p className='Waste-Text'>We're not here to waste your time or your money, because let's face it, it would be a waste of our time and money too. ;)</p>
      <motion.div type='button' className='Im-In-Button' onClick={toSalesPage}  >
      <a className='Im-In-Button-Text'>
      I’m in !
          </a>
      </motion.div>
      </div>
      
      <a className='Automatic-Renewal-Text'>
      *After your free trial, the yearly subscription is ${yearlyCost} USD and automatically renews each year. 
      </a>
      <a className='Terms-Conditions-Text'>
      By continuing, you agree to our <a style = {{color:'#8A8A8A'}} href='https://www.reframeapp.com/terms-of-use'>Terms of Service</a> and <a style = {{color:'#8A8A8A'}} href='https://www.reframeapp.com/privacy-policy'>Privacy Policy</a>
      </a>
      
    
      <motion.div type='button' className='Start-Free-Trial-Button' onClick={StartTrialClicked}  >
      <a className='Start-Free-Trial-Text'>
            Start 14-Day Free Trial
          </a>
      </motion.div>
      
   

    
      <a className='Cancel-Anytime-Text'>
      Cancel anytime
      </a>

      
      
     
    </motion.div>
    </motion.div>
  );
}

export default CustomPlan;
