import './ConfirmationCode.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Completed-Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import {confirmCode, resendCode} from './Functions'
import { inspect } from 'util'
import ReframeLogo from './Horizontal_Logo_Smaller.png';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
const Cognito = require("@aws-sdk/client-cognito-identity-provider");
function ConfirmationCode() {
  const region = 'us-east-1'

  async function  clickedConfirmCode (){
    var codeInput  = document.getElementsByClassName('Confirmation-Code-Text')[0];
    if(codeInput.value.length != 6 ){
      document.getElementsByClassName('Code-Error-Text')[0].textContent = 'Error: code should be 6 digits'
      return;
    }
    const authData =  {"email": localStorage.getItem("email"), 'code': codeInput.value}
    localStorage.setItem("cognitoObject", new Cognito.CognitoIdentityProviderClient({ region }));
    const response= await confirmCode(new Cognito.CognitoIdentityProviderClient({ region }), authData);
    var firsttime;
    
    if(localStorage.getItem("confirmFirstTime",  null) == null){
      firsttime = "true"
    }else{
      firsttime= "false"
    }
    localStorage.setItem("confirmFirstTime", "false");
    if(response.name === 'CodeMismatchException'){
      document.getElementsByClassName('Code-Error-Text')[0].textContent = 'Error: code is not correct'
    }else{
      const jsonData  = {
        "functionType" : "pageEvent",
        "userId": localStorage.getItem("UserID"),
        "firstTime":firsttime,
        "messageProperties": {
          "url": window.location.href,
          "path": window.location.pathname, 
          "host" : window.location.host, 
          "title": document.title, 
          "query": window.location.search,
          "referer": document.referrer},
        "context": {"userAgent":  navigator.userAgent}
  
      };
      console.log('thi si sthe body that was sent: ', jsonData);
      fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
    
      navigate('/customplan')
    }
    
    
  }

  async function clickedResendCode(){
    const authData =  {"email": localStorage.getItem("email")}
    localStorage.setItem("cognitoObject", new Cognito.CognitoIdentityProviderClient({ region }));
    const response= await resendCode(new Cognito.CognitoIdentityProviderClient({ region }), authData);
    document.getElementsByClassName('Code-Error-Text')[0].textContent = 'Code has been sent to ' + localStorage.getItem("email");

    console.log('this is the response: ', response)


  }
  const navigate = useNavigate()
  function goBack (){
      navigate('/signup')
  }
    console.log('Transition')
    console.log('this is the email: ', localStorage.getItem("email"))
  return (
    <motion.div>
    
    <motion.div className="ConfirmationCode" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="ConfirmationCode-header" src={ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick = {goBack}>
      </img>
      <div className='Confirmation-Main-Div'>
      <p className='Six-Digit-Text'>
      Check your email for a 6-digit confirmation code</p>
      <a className='Email-Sent-Text'>
      We sent an email to: <b>{localStorage.getItem("email")}</b>, make sure you check your spam folder too
      </a>

      <a className='Code-Error-Text'>
      
      </a>
      
      
      

      
      <input type="number" name=""city id="name" className='Confirmation-Code-Text' placeholder='6-digit code'/>

      

      <a className='Didnt-Get-Code-Text' onClick = {clickedResendCode}>
      Didn’t get the code?<b><u> Click to resend</u></b> 
      </a>
      </div>
     
   
      <motion.div type='button' className='Code-Ready-Button' onClick={clickedConfirmCode}   >
      <a className='Code-Ready-Text'>
      Next
    </a>
      </motion.div>
     
  

    
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default ConfirmationCode;
