import './CustomPlanTransitionScreen.css';
import React from 'react';

function CustomPlanTransitionScreen() {
    console.log('Transition')
  return (
    <div className="CustomPlanTransitionScreen">
      <header className="Custom-header">
      
        
      </header>
      <p>
      The world’s most comprehensive alcohol reduction platform
      </p>
      <div className='Transition-Section'>
      <div type='button' className='Next-Button' >
      
        </div>
        
      </div>
      
    
      
    </div>
  );
}

export default CustomPlanTransitionScreen;
