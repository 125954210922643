import './AlcoholReduction.css';
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import ReframeLogo from './Horizontal_Logo_Smaller.png';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function AlcoholReduction() {
  var previoslySelectedButton = "Quit-Div", previouslySelectedText = "Quit-Text";
  useEffect(() => {
    // Update the document title using the browser API
    
    if(!(localStorage.getItem("reductionPath") == null)){
      var buttonName, textName;
        switch(localStorage.getItem("reductionPath")){
          case "cutBack":
          buttonName ="Cut-Div";
          textName = "Cut-Text";
          break;
        case "quitDrinking":
          buttonName ="Quit-Div";
          textName = "Quit-Text";
          break;
        case "sober":
          buttonName ="Continue-Div";
          textName = "Continue-Text";
          break;
        case "notSure":
          buttonName ="Not-Sure-Div";
          textName = "Not-Sure-Text";
          break;
  

        }
        previoslySelectedButton = buttonName;
        previouslySelectedText = textName;
        var buttonElement = document.getElementsByClassName(buttonName)[0];
        buttonElement.style.background = "#213088";
        var buttonTextElement = document.getElementsByClassName(textName)[0];
        buttonTextElement.style.color = "#fff";
    }
    localStorage.setItem("firstTime", false);
  });
    const navigate = useNavigate();
    function toNextPage (tosave ){
      console.log('this is the reductionpath: ', tosave)
      global.reductionPath = tosave;
      var toSend;
      var firsttime;
      if(localStorage.getItem("reductionPath",  null)== null){
        firsttime = "true"
      }else{
        firsttime= "false"
      }
      localStorage.setItem("reductionPath", tosave);
      var buttonName, textName;
      switch(tosave){
        case "cutBack":
          buttonName ="Cut-Div";
          textName = "Cut-Text";
          toSend = "I want to cut back on drinking";
          break;
        case "quitDrinking":
          buttonName ="Quit-Div";
          textName = "Quit-Text";
          toSend = "I want to quit drinking";
          break;
        case "sober":
          buttonName ="Continue-Div";
          textName = "Continue-Text";
          toSend ="I’m already alcohol free and want to continue my journey";
          break;
        case "notSure":
          buttonName ="Not-Sure-Div";
          textName = "Not-Sure-Text";
          toSend = "I’m not sure yet";
          break;
      }
      var previousButtonElement = document.getElementsByClassName(previoslySelectedButton)[0];
      previousButtonElement.style.background = "#fff";
      var previousButtonTextElement = document.getElementsByClassName(previouslySelectedText)[0];
      previousButtonTextElement.style.color = "#213088";
      var buttonElement = document.getElementsByClassName(buttonName)[0];
      buttonElement.style.background = "#213088";
      var buttonTextElement = document.getElementsByClassName(textName)[0];
      buttonTextElement.style.color = "#fff";
      console.log('this is the userID before: ', localStorage.getItem("UserID"))
      const jsonData  = {
        "functionType" : "updateUser",
        "attributeType": "tracks",
        "value": tosave,
        "firstTime":firsttime, 
        "userId": localStorage.getItem("UserID"),
        "messageProperties": {
          "url": window.location.href,
          "path": window.location.pathname, 
          "host" : window.location.host, 
          "title": document.title, 
          "query": window.location.search,
          "referer": document.referrer},
        "context": {"userAgent":  navigator.userAgent}
  
      };
      fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
      
      setTimeout(() => {
        navigate('/age')
      }, 750);
      
    }
    function goBack (){
      navigate('/')
    }

  
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="AlcoholReuction" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="Reduction-header" src={ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick = {goBack}>
      </img>


      
      <a className='Which-Path'>
      Which alcohol-reduction path feels right for you?
      </a>
      <div className='Alcohol-Main-Div'>
      <div className='Quit-Div' onClick = {() => toNextPage('quitDrinking')}>
        <a className='Quit-Text'>
        Quit drinking
        </a>
      </div>
      <div className='Cut-Div' onClick = {() => toNextPage('cutBack')}>
        <a className='Cut-Text'>
        Cut back on drinking      
     </a>
      </div>
      <div className='Continue-Div' onClick = {() => toNextPage('sober')}>
        <a className='Continue-Text'>
        Already alcohol free and want to continue my journey
                </a>
      </div>
      <div className='Not-Sure-Div' onClick  = {() => toNextPage('notSure')}>
        <a className='Not-Sure-Text'>
        Not sure yet
        </a>
      </div>
      
      </div>
      
     
      
     
      
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default AlcoholReduction;
