import './HelpYou.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function HelpYou() {
    console.log('Transition')
  return (
    <motion.div>
    
    
    <motion.div className="HelpYou" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="HelpYou-header">
      
        
      </header>
      <motion.div className='Circles-Section'>
      </motion.div>
      <a className='Cut-Back'>
      Great! We will help you to cut back.
      </a>
      <p>
      Based on how much you want to change your current drinking habits, we put together lessons that will make sense for you.
<br></br><br></br>
You can change your path at 
any time.  
      </p>
      <Link to ='/transition' replace= {true}>
    <motion.span className="Cut-Back-Help-You-Ready-Section" style={{"display": "block"}}>
      <motion.div type='button' className='Cut-Back-Help-You-Ready-Button'   >
      <a className='Cut-Back-Help-You-Ready-Text'>
            Next
          </a>
      </motion.div>
      
    </motion.span>

    </Link>
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default HelpYou;
