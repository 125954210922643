import './HowManyDrinks.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
  const navigate = useNavigate();
    function toNextPage ( ){
      navigate('/hearabout')
    }
    function saveDrinks (){
      var drinksInput = document.getElementsByClassName('Drinks-Num-Input')[0];
      global.drinksCutBack =  drinksInput.value;
      toNextPage();
    }
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="HowManyDrinks" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="HowManyDrinks-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Many-Progress-Bar'>
      <div className='Many-Progress-Indicator'></div>
      <div className='Many-Progress-Indicator-Gradient'></div>

        <div className='Many-Name-Icon-Div'>
        
            <img className='Many-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Many-Flag-Icon-Div'>
        
            <img className='Many-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Many-Pin-Icon-Div'>
        
            <img className='Many-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Many-Brain-Icon-Div'>
        
            <img className='Many-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Many-Check-Icon-Div'>
        
            <img className='Many-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
      
     
      
      <div className='Form-Div'>
      <a className='Question'>
      How many drinks do you want to cutback? 
      </a>
      <form action = "">
      
      <input type="number" name="" className='Drinks-Num-Input' placeholder=''></input>

      </form>
      </div>
     
      
     
      
     
     
    <motion.span className="Many-Ready-Section" style={{"display": "block"}}>
      <motion.div type='button' className='Many-Ready-Button' onClick={saveDrinks}  >
      <a className='Many-Ready-Text'>
            Next
          </a>
      </motion.div>
      
    </motion.span>

      
    
      
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;