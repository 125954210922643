import './IdentifyScreen.css';
import {AnimatePresence, motion} from 'framer-motion'
import React, {useEffect} from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Flag-Icon.jpg';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import ReframeLogo from './Horizontal_Logo_Smaller.png';



import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
  var previoslySelectedButton = "Female-Div", previouslySelectedText = "Female-Text";
  useEffect(() => {
    // Update the document title using the browser API
    
    if(!(localStorage.getItem("identity") == null)){
      var buttonName, textName;
        switch("Female"){
          case "Female":
            buttonName ="Female-Div";
            textName = "Female-Text";
            break;
          case "Male":
            buttonName ="Male-Div";
            textName = "Male-Text";
            break;
        
        
          case "Prefer not to answer":
            buttonName ="Prefer-Div";
            textName = "Prefer-Text";
            break;
  

        }
        previoslySelectedButton = buttonName;
        previouslySelectedText = textName;
        console.log("this is the buttonname: ", buttonName);
        var buttonElement = document.getElementsByClassName(buttonName)[0];
        buttonElement.style.background = "#213088";
        var buttonTextElement = document.getElementsByClassName(textName)[0];
        buttonTextElement.style.color = "#fff";
    }
    console.log('this is the user name from local storage: ', localStorage.getItem("name"));
  });
  const navigate = useNavigate();
    function toNextPage (tosave){
      console.log('To Next page', tosave);
      global.identity = tosave;
      var firsttime;
    
    if(localStorage.getItem("identity",  null)== null){
      firsttime = "true"
    }else{
      firsttime= "false"
    }
      localStorage.setItem("identity", tosave);
      var buttonName, textName;
      switch(tosave){
        case "Female":
          buttonName ="Female-Div";
          textName = "Female-Text";
          break;
        case "Male":
          buttonName ="Male-Div";
          textName = "Male-Text";
          break;
 
       
        case "Prefer not to answer":
          buttonName ="Prefer-Div";
          textName = "Prefer-Text";
          break;
      }
    
      var previousButtonElement = document.getElementsByClassName(previoslySelectedButton)[0];
      previousButtonElement.style.background = "#fff";
      var previousButtonTextElement = document.getElementsByClassName(previouslySelectedText)[0];
      previousButtonTextElement.style.color = "#213088";
      var buttonElement = document.getElementsByClassName(buttonName)[0];
      buttonElement.style.background = "#213088";
      var buttonTextElement = document.getElementsByClassName(textName)[0];
      buttonTextElement.style.color = "#fff";
      console.log('this is the userID before: ', localStorage.getItem("UserID"))
      const jsonData  = {
        "functionType" : "updateUser",
        "attributeType": "pronous",
        "value": tosave,
        "userId": localStorage.getItem("UserID"),
        "firstTime": firsttime,
        "messageProperties": {
          "url": window.location.href,
          "path": window.location.pathname, 
          "host" : window.location.host, 
          "title": document.title, 
          "query": window.location.search,
          "referer": document.referrer},
        "context": {"userAgent":  navigator.userAgent}

      };
    fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
  
      setTimeout(() => {
        navigate('/signup')
        }, 750);
      
    }
    function goBack (){
      navigate('/typicalweek')
    }
  return (
    <motion.div>
    
    <motion.div className="IdentifyScreen" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="IdentifyScreen-header" src = {ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick = {goBack}>
      </img>
      
      <p className='Thanks-Text'>
      Thanks {global.name}, sex and hormones impact how our body responds to alcohol. 
</p>
      <a className='How-Identify'>
      How do you identify?
      </a>
      <div className='Identity-Main-Div'>
      <div className='Female-Div' onClick={() => toNextPage("Female")}>
        <a className='Female-Text'>
        Female
        </a>
      </div>
      <div className='Male-Div' onClick = {() => toNextPage("Male")}>
        <a className='Male-Text'>
        Male
     </a>
      </div>
      
      <div className='Prefer-Div' onClick = {() => toNextPage("Prefer not to answer")}>
        <a className='Prefer-Text'>
        Prefer not to answer
        </a>
      </div>
      </div>
     
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;
