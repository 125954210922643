import './TypeDrink.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import ReframeLogo from './Horizontal_Logo_Smaller.png';



import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function TypeDrink() {
  const navigate = useNavigate();
  function toNextPage ( tosave){
    global.TypeDrink = tosave;
    navigate('/triedbefore')
  }
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="TypeDrink" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="TypeDrink-header" src = {ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Type-Drink-Progress-Bar'>
      <div className='Type-Drink-Progress-Indicator'></div>
      <div className='Type-Drink-Progress-Indicator-Gradient'></div>
        <div className='Type-Drink-Name-Icon-Div'>
        
            <img className='Type-Drink-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Type-Drink-Flag-Icon-Div'>
        
            <img className='Type-Drink-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Type-Drink-Pin-Icon-Div'>
        
            <img className='Type-Drink-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Type-Drink-Brain-Icon-Div'>
        
            <img className='Type-Drink-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Type-Drink-Check-Icon-Div'>
        
            <img className='Type-Drink-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
    
      <a className='Type-Drink-Text'>
      What type of beverage do you typically drink?      </a>
      <div className='Type-Main-Div'>
      <div className='Beer-Div' onClick={() => toNextPage("Beer")}>
        <a className='Beer-Text'>
        Beer
        </a>
      </div>
      <div className='Cocktails-Div' onClick={() => toNextPage("Cocktails")}>
        <a className='Cocktails-Text'>
        Cocktails      
     </a>
      </div>
      <div className='Wine-Div' onClick={() => toNextPage("Wine")}>
        <a className='Wine-Text'>
        Wine
                </a>
      </div>
      <div className='Spirits-Div' onClick={() => toNextPage("Spirits")}>
        <a className='Spirits-Text'>
        Spirits
        </a>
      </div>
     
    
      </div>
     
    </motion.div>
    </motion.div>
  );
}

export default TypeDrink;
