import React from 'react';
require('dotenv').config()
const { DynamoDBClient } = require("@aws-sdk/client-dynamodb");
const { DynamoDBDocumentClient, UpdateCommand } = require("@aws-sdk/lib-dynamodb");
const { MY_AWS_ACCESS_KEY_ID, MY_AWS_SECRET_ACCESS_KEY, MY_AWS_REGION } = process.env
const usersTable = 'bluesignupversioninfo-aiuun7led5ckhcdqwlktyyzqvm-craving'
/*exports.handler = async (event, context) => {
  if (event.httpMethod !== 'POST' || !event.body) {
    return { statusCode: 400, body: 'Invalid request' };
  }
  const dynamoDBClient = new DynamoDBClient({ region: MY_AWS_REGION, credentials: {
    accessKeyId: MY_AWS_ACCESS_KEY_ID, secretAccessKey: MY_AWS_SECRET_ACCESS_KEY
  } });
  const ddbDocClient = new DynamoDBDocumentClient(dynamoDBClient);
  const onboardingFormData = JSON.parse(event.body);
  const response = await storeDataToDB(ddbDocClient, onboardingFormData);
  console.log(response);
  if (response["$metadata"].httpStatusCode !== 200) {
    const errorBody = JSON.stringify({"error": {
      "message": response.message,
      "name": response.name
    }});
    return { statusCode: response["$metadata"].httpStatusCode, body: errorBody };
  }
  return { statusCode: response["$metadata"].httpStatusCode, body: JSON.stringify(response["$metadata"]) };
};*/
export async function storeDataToDB(ddbDocClient, onboardingFormData) {
    console.log(onboardingFormData);
    const cleanedUpdateExpression = [
        onboardingFormData.first_name == "" ? null : '#name = :first_name',
        onboardingFormData.email == "" ? null : 'email = :email',
        onboardingFormData.how_did_you_hear_about_us == "" ? null : 'howdidyouhearus = :how_did_you_hear_about_us',
        onboardingFormData.sex == "" ? null : 'pronous = :sex',
        onboardingFormData.age_range == "" ? null : 'agerange = :age_range',
        onboardingFormData.why_change_drinking_habits_1 == "" ? null : 'primarygoal = :primary_goal',
        onboardingFormData.why_change_drinking_habits_2 == "" ? null : 'secondarygoal = :secondary_goal',
        onboardingFormData.alcohol_reduction_path == "" ? null : 'tracks = :track',
        onboardingFormData.drinks_per_week == "" ? null : 'drinksperweek = :drinks_per_week',
        onboardingFormData.average_cost_per_week == "" ? null : 'averagecostperweek = :average_cost_per_week',
        onboardingFormData.type_of_drink == "" ? null : 'typeofdrinks = :drink_type',
        onboardingFormData.tried_to_cutback_in_the_past == "" ? null : 'quitdrinkingbefore = :quit_drinking_before',
        onboardingFormData.how_often_has_alcohol_interfered == "" ? null : 'frequency_interferedlife = :alcohol_interference_frequency',
        onboardingFormData.reasons_to_drink == [] ? null : 'reasonstodrink = :reasons_to_drink',
    ].filter(data => data).join(", ")
    const ExpressionAttributeValues = Object.assign({},
        onboardingFormData.first_name == "" ? null : { ':first_name': onboardingFormData.first_name },
        onboardingFormData.email == "" ? null : { ':email': onboardingFormData.email },
        onboardingFormData.how_did_you_hear_about_us == "" ? null : { ':how_did_you_hear_about_us': onboardingFormData.how_did_you_hear_about_us },
        onboardingFormData.sex == "" ? null : { ':sex': onboardingFormData.sex },
        onboardingFormData.age_range == "" ? null : { ':age_range': onboardingFormData.age_range },
        onboardingFormData.why_change_drinking_habits_1 == "" ? null : { ':primary_goal': onboardingFormData.why_change_drinking_habits_1},
        onboardingFormData.why_change_drinking_habits_2 == "" ? null : { ':secondary_goal': onboardingFormData.why_change_drinking_habits_2 },
        onboardingFormData.alcohol_reduction_path == "" ? null : { ':track': onboardingFormData.alcohol_reduction_path },
        onboardingFormData.drinks_per_week == "" ? null : { ':drinks_per_week': onboardingFormData.drinks_per_week },
        onboardingFormData.average_cost_per_week == "" ? null : { ':average_cost_per_week': onboardingFormData.average_cost_per_week },
        onboardingFormData.type_of_drink == "" ? null : { ':drink_type': onboardingFormData.type_of_drink },
        onboardingFormData.tried_to_cutback_in_the_past == "" ? null : { ':quit_drinking_before': onboardingFormData.tried_to_cutback_in_the_past },
        onboardingFormData.how_often_has_alcohol_interfered == "" ? null : { ':alcohol_interference_frequency': onboardingFormData.how_often_has_alcohol_interfered },
        onboardingFormData.reasons_to_drink == [] ? null : { ':reasons_to_drink': onboardingFormData.reasons_to_drink },
    )
  console.log(cleanedUpdateExpression);
  console.log(ExpressionAttributeValues);
  try {
    const response = await ddbDocClient.send(new UpdateCommand({
        TableName: usersTable,
        Key: { "id": onboardingFormData.userId },
        UpdateExpression:
            'set ' + cleanedUpdateExpression,
        ExpressionAttributeValues,
        ExpressionAttributeNames: {
            "#name": "name",
        }
    }));
    return response;
  } catch (err) {
    return err;
  }
}