import './SignUp.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Completed-Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";
import {login, signUp} from './Functions'
import { inspect } from 'util'
import ReframeLogo from './Horizontal_Logo_Smaller.png';

import { storeDataToDB } from './DatabaseFunctions';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

const { DynamoDBClient } = require("@aws-sdk/client-dynamodb");
const { DynamoDBDocumentClient, UpdateCommand } = require("@aws-sdk/lib-dynamodb");
const { MY_AWS_ACCESS_KEY_ID, MY_AWS_SECRET_ACCESS_KEY, MY_AWS_REGION } = process.env
const Cognito = require("@aws-sdk/client-cognito-identity-provider");

function SignUp() {
const region = 'us-east-1'
  const navigate = useNavigate();
  var termsAgreed =false;
  async function signUpClicked(){
    var emailInput = document.getElementsByClassName('Email-Input')[0];
    var passwordInput = document.getElementsByClassName('Password-Input')[0];

    if(emailInput.value === emailInput.placeHolder || emailInput.value === ""){
      document.getElementsByClassName('Error-Text')[0].textContent = "Error: enter your email"
      return;
    }
    if(passwordInput.value === passwordInput.placeHolder || passwordInput.value === ""){
      document.getElementsByClassName('Error-Text')[0].textContent = "Error: enter your password"
        return;
    }
    if(passwordInput.value.length < 8){
      document.getElementsByClassName('Error-Text')[0].textContent = "Error: password must be at least 8 characters"
        return;
    }
    if(!termsAgreed){
      document.getElementsByClassName('Error-Text')[0].textContent = "Please agree to the terms and confitions"
      return;
    }
   
    document.getElementsByClassName('Error-Text')[0].textContent =""
    console.log("this is the reduction: ",  localStorage.getItem("reductionPath"));
    const authData =  {"email": emailInput.value, "password":passwordInput.value, 'programTrack': localStorage.getItem("reductionPath")}
    localStorage.setItem("cognitoObject", new Cognito.CognitoIdentityProviderClient({ region }));
    const response= await signUp(new Cognito.CognitoIdentityProviderClient({ region }), authData);
    var firsttime;
    
    if(localStorage.getItem("email",  null)== null){
      firsttime = "true"
    }else{
      firsttime= "false"
    }
    localStorage.setItem("email", emailInput.value);
    console.log('this is the resonse array ', inspect(response));
    if(response.CodeDeliveryDetails == null ||response.CodeDeliveryDetails == undefined ){
      if(response.name === 'InvalidParameterException'){
        document.getElementsByClassName('Error-Text')[0].textContent = "Error: enter a valid email"
      }else if(response.name === 'UsernameExistsException'){
        document.getElementsByClassName('Error-Text')[0].textContent = "Error: this email is already associated with an account"

      }else{
        document.getElementsByClassName('Error-Text')[0].textContent = "Error: try again"
      }

    }else{
    console.log('this is the userID: ', response.UserSub);
    var anonId = localStorage.getItem("UserID");
    console.log('this is the userID before: ', localStorage.getItem("UserID"))
    localStorage.setItem("UserID", response.UserSub);
  
      const jsonData  = {
        "functionType" : "recreateUser",
        "anonymousId": anonId,
        "registeredId": response.UserSub,
        "email": emailInput.value,
        "firstTime":firsttime,
        "messageProperties": {
          "url": window.location.href,
          "path": window.location.pathname, 
          "host" : window.location.host, 
          "title": document.title, 
          "query": window.location.search,
          "referer": document.referrer},
        "context": {"userAgent":  navigator.userAgent}

  
      };
      console.log('this is the jsondata: ', jsonData)
      fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
    
    
    
    
    navigate('/confirmationcode')
    }
    
  }
  function goBack(){
    navigate('/identity')
  }
  function termsClicked(){
    var agreementCircle = document.getElementsByClassName('Agreement-Circle')[0];
    agreementCircle.style.border = "1px solid #213088";
    var agreementCircleFilled = document.getElementsByClassName('Filled-Agreement-Circle')[0];
    agreementCircleFilled.style.opacity = 1;
    var termsText = document.getElementsByClassName('Terms-Text')[0];
    termsText.style.color = '#213088';
    var termsOfUse = document.getElementsByClassName('Terms-Of-Use')[0];
    termsOfUse.style.color = '#213088'
    var privacyPolicy = document.getElementsByClassName('Privacy-Policy-Text-Sign-Up')[0];
    privacyPolicy.style.color = '#213088'
      termsAgreed = true;
  }
  function emailFocus(){
    console.log('the focus function was called');
    var agreementCircle = document.getElementsByClassName('Email-Input')[0];
    agreementCircle.style.visibility = 'visible';
    agreementCircle.focus();
    var agreementCircle = document.getElementsByClassName('Email-Text')[0];
    agreementCircle.style.visibility = 'visible';
    var agreementCircle = document.getElementsByClassName('Placeholder-Email-Text')[0];
    agreementCircle.style.visibility = 'hidden';
  }

  function passwordFocus(){
    console.log('the focus function was called');
    var agreementCircle = document.getElementsByClassName('Password-Input')[0];
    agreementCircle.style.visibility = 'visible';
    agreementCircle.focus();
    var agreementCircle = document.getElementsByClassName('Password-Text')[0];
    agreementCircle.style.visibility = 'visible';
    var agreementCircle = document.getElementsByClassName('Placeholder-Password-Text')[0];
    agreementCircle.style.visibility = 'hidden';
  }
    
  return (
    <motion.div>
    
    <motion.div className="SignUp" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="SignUp-header" src={ReframeLogo}>
      
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick={goBack}>
      </img>
     

      <a className='Error-Text'>
      
      </a>
      
      <div className='Sign-Up-Main-Div'>
     
      
      <a className='Email-Text' onClick={emailFocus}>
      E-mail*
      </a>

      <div className='Placeholder-Email-Text' type='button' onClick={emailFocus}>Email*</div>
        
      <input type="email" name="" id = 'email'  className='Email-Input' placeholder='e-mail' /> 
      
      <a className='Password-Text'>
      Password*
      </a>
      <div className='Placeholder-Password-Text' type='button' onClick={passwordFocus}> Password*</div>

       
      <input type="password" name="" id = 'password'  className='Password-Input' placeholder='password' />

    
      </div>
      <div className='Terms-Div'>
      <p className='Sign-Up-Text'>
      Sign up so we can create your custom plan
      
      </p>
      <a className='Required-Field'>
      *These fields are required.
      </a>
      <div className='Agreement-Circle' onClick = {termsClicked}>
      </div>
      <div className='Filled-Agreement-Circle' onClick = {termsClicked} >
      </div>
      <a className='Terms-Text'>
      I agree to the&nbsp;<b ><a className='Terms-Of-Use' style = {{color:'#8A8A8A'}} href='https://www.reframeapp.com/terms-of-use'>terms of agreement</a></b>&nbsp;and&nbsp;<b><a className = 'Privacy-Policy-Text-Sign-Up' style = {{color:'#8A8A8A'}} href='https://www.reframeapp.com/privacy-policy'>Privacy Policy </a></b>
      </a>
      </div>
      
      
     
      
     
     
   
      <motion.div type='button' className='Sign-Up-Ready-Button' onClick={signUpClicked}   >
      <a className='Sign-Up-Ready-Text'>
      Next
    </a>
      </motion.div>
     
  

    
      <a className='Disclaimer-Text'>
      We use your email to loop you into better ways to use Reframe. We take your privacy very seriously, and will never share your information.</a>
    
      
    </motion.div>
    </motion.div>
  );
}


export default SignUp;