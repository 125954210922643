import { Navigate, useNavigate } from "react-router-dom";
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Custom from './CustomPlanTransitionScreen'
import {AnimatePresence, motion} from 'framer-motion'
import Initial from './InitialOnboardingScreen'
import Map from './MapOnboardingScreen'
import Research from './ResearchAnalyticsScreen'
import Doctor from './DoctorScreen'
import Name from './NameScreen'
import AlcoholReduction from'./AlcoholReductionScreen.js';
import HowManyDrinks from './HowManyDrinksScreens.js';
import HearAbout from './HearAboutScreen.js';
import IdentifyScreen from './IdentifyScreen.js';
import Age from './AgeRangeScreen.js';
import HealthierDrinking from './HealthierDrinkingScreen.js';
import WhyChange from './WhyChangeScreen.js';
import HelpYouScreen from './HelpYouScreen.js';
import AllInOne from './AllInOneScreen.js';
import NextQuestions from './NextQuestionsScreen.js';
import TypicalWeek from './TypicalWeekScreen.js';
import DrinkingChart from './DrinkingChartScreen.js';
import TypeDrink from './TypeDrinkScreen.js';
import TriedBefore from './TriedBeforeScreen.js';
import AlcoholInterfered from './AlcoholInterferedScreen.js';
import DrinkingReasons from './DrinkingReasonsScreen.js';
import ToPersonalizedPlan from './ToPersonalizedPlanScreen.js';
import KeepThatInMind from './KeepThatInMindScreen.js';
import GotYourBack from './GotYourBackScreen.js';
import RememberReasons from './RememberReasonsScreen.js';
import ReframersNoted from './ReframersNotedScreen.js';
import SignUp from './SignUpScreen.js';
import ConfirmationCode from './ConfirmationCodeScreen.js';
import YoullSave from './YoullSaveScreen.js';
import VennDiagram from './VennDiagramScreen.js';
import CustomPlan from './CusotmPlanScreen.js';
import AllDone from './AllDoneScreen.js';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Link
} from 'react-router-dom';


 

function App() {
  
  return (
  <div>
  </div>
    
  );
}

export default App;
