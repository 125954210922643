import './TypicalWeek.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import coloredMinus from './Colored-Minus-In-Circle.svg'
import Minus from './Minus-In-Circle.svg'
import { Navigate, useNavigate } from "react-router-dom";
import ReframeLogo from './Horizontal_Logo_Smaller.png';



import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';

import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function TypicalWeek() {

  const navigate = useNavigate();
  function updatePrice(e){
      global.price = e.target.value;
      console.log('this is the price: ', global.price);
  }
  const MinusStyling = {
    backgroundImage: `url(${Minus})`,
    position: 'absolute',
    left: '60%',
  
    top:'39.78%',
    width: '18px',
    height: '18px',
    
    /* Brand Colors/Blue */
    
    fill: '#018ABE',
    cursor: 'pointer'

    
    /* Completed */
    
   
};
const ColoredMinusStyling = {
  backgroundImage: `url(${coloredMinus})`,
  position: 'absolute',
  left: '60%',

  top:'39.78%',
  width: '18px',
  height: '18px',
  
  /* Brand Colors/Blue */
  
  cursor: 'pointer'

  
  /* Completed */
  
 
};
  

  global.drinkCount = 0;
  global.drinkPrice = 10;
  function toNextPage(){
    var DrinkInput = document.getElementsByClassName('Typical-Drinks-Per-Week-Counter-Text')[0];
    var PriceInput = document.getElementsByClassName('Price-Per-Week-Counter-Text')[0];
    global.drinksPerWeek = parseInt(DrinkInput.value);
    global.pricePerWeek = parseInt(PriceInput.value);
    var firsttime;
    if(localStorage.getItem("pricePerWeek",  null)== null){
      firsttime = "true"
    }else{
      firsttime= "false"
    }
    localStorage.setItem("pricePerWeek", parseInt(PriceInput.value));

    localStorage.setItem("drinksPerWeek", parseInt(DrinkInput.value));

    console.log('this is the price per week', global.pricePerWeek);
    console.log('this is the userID before: ', localStorage.getItem("UserID"))
    
    //fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
  
    console.log('this is the userID before: ', localStorage.getItem("UserID"))
    const jsonData2  = {
      "functionType" : "updateUser",
      "attributeType": ["drinksperweek","averagecostperweek"],
      "value": [DrinkInput.value,PriceInput.value],
      "userId": localStorage.getItem("UserID"),
      "firstTime":firsttime,
      "messageProperties": {
        "url": window.location.href,
        "path": window.location.pathname, 
        "host" : window.location.host, 
        "title": document.title, 
        "query": window.location.search,
        "referer": document.referrer},
      "context": {"userAgent":  navigator.userAgent}

    };
    fetch('https://l454b6axitypdmtwhxusufbdui0nhcnw.lambda-url.us-east-1.on.aws/', {method: 'POST', body:JSON.stringify(jsonData2)}).then(response => response.json()).then(data => console.log('this is the response: ' , data))
  
    navigate("/identity")
  }
  function goBack (){
    navigate('/age')
  }
  function drinkChanged(){
    var DrinkInput = document.getElementsByClassName('Typical-Drinks-Per-Week-Counter-Text')[0];
    
    if(DrinkInput.value == ""){
      global.drinkCount = 0;
    }else if(parseInt(DrinkInput.value) <0 ){
        global.drinkCount = 0;
        DrinkInput.value = global.drinkCount;
    }else{
      global.drinkCount = parseInt(DrinkInput.value);
    }
    if(global.drinkCount == 0){
      var MinusButton = document.getElementsByClassName('Minus-Drinks-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${Minus})`;
    }else{
      var MinusButton = document.getElementsByClassName('Minus-Drinks-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${coloredMinus})`;
    }
    console.log('this is the drinkcount: ', global.drinkCount)
    var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }

  function priceChanged(){
    var PriceInput = document.getElementsByClassName('Price-Per-Week-Counter-Text')[0];
    console.log('this is the price per week', PriceInput.value);
    if(PriceInput.value == "" || PriceInput.value == "0" ){
      global.drinkPrice = 0;
    }else if(parseInt(PriceInput.value) <0 ){
        global.drinkPrice = 0;
        PriceInput.value = global.drinkPrice;
    }else{
      global.drinkPrice = parseInt(PriceInput.value);
    }
    if(global.drinkPrice == 0){
      var MinusButton = document.getElementsByClassName('Minus-Price-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${Minus})`;
    }else{
      var MinusButton = document.getElementsByClassName('Minus-Price-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${coloredMinus})`;
    }
    console.log('this is the price: ', global.drinkPrice)
    var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }

  function addDrink (){
    var DrinkInput = document.getElementsByClassName('Typical-Drinks-Per-Week-Counter-Text')[0];
      global.drinkCount += 1;
      console.log('this is the drink count', global.drinkCount)
      DrinkInput.value = global.drinkCount;
      var MinusButton = document.getElementsByClassName('Minus-Drinks-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${coloredMinus})`;
      var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }

  function minusDrink (){
      if(global.drinkCount != 0){
        var DrinkInput = document.getElementsByClassName('Typical-Drinks-Per-Week-Counter-Text')[0];
      global.drinkCount -= 1;
      DrinkInput.value = global.drinkCount;
    }
    if(global.drinkCount == 0){
      var MinusButton = document.getElementsByClassName('Minus-Drinks-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${Minus})`;
    }
    var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }
  function addPrice (){
    var PriceInput = document.getElementsByClassName('Price-Per-Week-Counter-Text')[0];
    global.drinkPrice += 1;
    PriceInput.value = global.drinkPrice;
    var MinusButton = document.getElementsByClassName('Minus-Price-Per-Week')[0];
    MinusButton.style.backgroundImage = `url(${coloredMinus})`;
    var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }
  function minusPrice (){
    if(global.drinkPrice != 0){
      global.drinkPrice -= 1;  
      var PriceInput = document.getElementsByClassName('Price-Per-Week-Counter-Text')[0];
     
      PriceInput.value = global.drinkPrice;
    }
    if(global.drinkPrice == 0){
      var MinusButton = document.getElementsByClassName('Minus-Price-Per-Week')[0];
      MinusButton.style.backgroundImage = `url(${Minus})`;
    }
    var perYearText = document.getElementsByClassName('Total-Spending-Per-Year')[0].childNodes[0];
    perYearText.textContent = '$' + global.drinkPrice*global.drinkCount*52;
    var perMonthText = document.getElementsByClassName('Total-Spending-Per-Month')[0].childNodes[0];
    perMonthText.textContent = '$' + global.drinkPrice*global.drinkCount*4;
  }
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="TypicalWeek" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <img className="TypicalWeek-header" src={ReframeLogo}>
        
      </img>
      <img className='Back-Arrow' src = {BackArrow} onClick={goBack}>
      </img>
      
      <a className='Typical-Week-Question'>
      What did a typical week look like for you, {global.name}? 
      </a>

      <a className='Drinks-Text'>
      Drinks
      </a>
      <a className='Per-Week-Drinks-Text'>
      per week
      </a>
      <div className='Drinks-Per-Week-Div'>
        <div className='Minus-Drinks-Per-Week' onClick={minusDrink} >

        </div>
        <div className='Drinks-Input-Div'>
        
        <input type='number' defaultValue='0' className='Typical-Drinks-Per-Week-Counter-Text' onChange={drinkChanged} />
      
        </div>
        <div className='Plus-Drinks-Per-Week' onClick={addDrink}>

        </div>
        </div>
    
        <a className='Price-Text'>
        Average cost
      </a>
      <a className='Per-Week-Price-Text'>
      per drink($)
      </a>
      <div className='Price-Per-Week-Div'>
        <div className='Minus-Price-Per-Week' onClick={minusPrice}>
          <img src=''>
          </img>
        </div>
        <div className='Price-Input-Div'>
          <a class="Dollar-Sign">$</a>
          <input type='number' className='Price-Per-Week-Counter-Text' defaultValue='10' onChange={priceChanged}/>
        </div>
        
        
        <div className='Plus-Price-Per-Week' onClick={addPrice}>

        </div>
        </div>

        <a className='Total-Spending-Text'>
        You’re total spending:
        </a>

        <a className='Total-Spending-Per-Month'>
        $0
        </a>
        <a className='Per-Month-Text'>per month</a>
        <a className='Total-Spending-Per-Year'>
        $0
        </a>
        <a className='Per-Year-Text'>per year</a>
    
      <motion.div type='button' className='Typical-Ready-Button'  onClick={toNextPage} >
      <a className='Typical-Ready-Text'>
            Next
          </a>
      </motion.div>
           
    </motion.div>
    </motion.div>
  );
}

export default TypicalWeek;
