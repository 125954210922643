import './TriedBefore.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function TriedBefore() {
  const navigate = useNavigate();
  function toNextPage ( tosave){
    global.TriedBefore = tosave;
    navigate('/triedbefore')
  }
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="TriedBefore" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="TriedBefore-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Tried-Before-Progress-Bar'>
      <div className='Tried-Before-Progress-Indicator'></div>
      <div className='Tried-Before-Progress-Indicator-Gradient'></div>
        <div className='Tried-Before-Name-Icon-Div'>
        
            <img className='Tried-Before-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Tried-Before-Flag-Icon-Div'>
        
            <img className='Tried-Before-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Tried-Before-Pin-Icon-Div'>
        
            <img className='Tried-Before-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Tried-Before-Brain-Icon-Div'>
        
            <img className='Tried-Before-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Tried-Before-Check-Icon-Div'>
        
            <img className='Tried-Before-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
    
      <a className='Tried-Before-Text'>
      Have you tried to cut back or quit in the past, unsuccessfully?      </a>
      <div className='Tried-Main-Div'>
      <div className='Tried-Yes-Div' onClick={() =>  toNextPage(true)}>
        <a className='Tried-Yes-Text'>
        Yes
        </a>
      </div>
      <div className='Tried-No-Div' onClick={() =>  toNextPage(false)}>
        <a className='Tried-No-Text'>
        No      
     </a>
      </div>
    
      </div>
     
    </motion.div>
    </motion.div>
  );
}

export default TriedBefore;
