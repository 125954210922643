import './NextQuestions.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function NextQuestions() {
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="NextQuestions" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="NextQuestions-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Questions-Progress-Bar'>
      <div className='Questions-Progress-Indicator'></div>
      <div className='Questions-Progress-Indicator-Gradient'></div>
        <div className='Questions-Name-Icon-Div'>
        
            <img className='Questions-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Questions-Flag-Icon-Div'>
        
            <img className='Questions-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Questions-Pin-Icon-Div'>
        
            <img className='Questions-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Questions-Brain-Icon-Div'>
        
            <img className='Questions-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Questions-Check-Icon-Div'>
        
            <img className='Questions-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
      <a className='Remember-Reframe'>
      Remember, Reframe is a safe space to explore your habits with alcohol. 
      </a>
      <a className='Next-Questions-Text'>
      The next few questions may be hard. But make sure you answer as honestly as possible.</a>

     
      
     
      
     
      <Link to ='/transition' replace= {true}>
    
      <motion.div type='button' className='Questions-Ready-Button'   >
      <a className='Questions-Ready-Text'>
            Next
          </a>
      </motion.div>
      
   

    </Link>
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default NextQuestions;
