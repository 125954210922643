import './AlcoholInterfered.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function AlcoholInterfered() {
    const navigate = useNavigate();
    function toNextPage (tosave){
      console.log('this is the interference', tosave);
      global.interfered =tosave;
      navigate('/gotyourback')
    }


  return (
    <motion.div>
    
    <motion.div className="AlcoholInterfered" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="AlcoholInterfered-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Interfered-Progress-Bar'>
      <div className='Interfered-Progress-Indicator'></div>
      <div className='Interfered-Progress-Indicator-Gradient'></div>
        <div className='Interfered-Name-Icon-Div'>
        
            <img className='Interfered-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Interfered-Flag-Icon-Div'>
        
            <img className='Interfered-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Interfered-Pin-Icon-Div'>
        
            <img className='Interfered-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Interfered-Brain-Icon-Div'>
        
            <img className='Interfered-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Interfered-Check-Icon-Div'>
        
            <img className='Interfered-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
    
      <a className='Alcohol-Interfered-Text'>
      How often has alcohol interfered with your life?     </a>
      <div className='Alcohol-Interfered-Main-Div'>
      <div className='Often-Div' onClick = {() => toNextPage("Often")}>
        <a className='Often-Text'>
        Often
        </a>
      </div>
      <div className='Sometimes-Div' onClick = {() => toNextPage("Sometimes")}>
        <a className='Sometimes-Text' >
        Sometimes      
     </a>
      </div>
      <div className='Occasionally-Div' onClick = {() => {toNextPage("Occasionally")} }>
        <a className='Occasionally-Text'>
        Occasionally      
     </a>
      </div>
      <div className='Never-Div' onClick = {() => toNextPage("Never")}>
        <a className='Never-Text'>
        Never      
     </a>
      </div>
    
      </div>
     
    </motion.div>
    </motion.div>
  );
}

export default AlcoholInterfered;
