import './ReframersNoted.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Completed-Pin-Icon.svg';
import Brain from './Completed-Brain-Icon.svg';
import Check from './Check-Icon.svg';
import Lottie from 'react-lottie';
import animationData from './Comp 1_2.mp4.lottie.json'

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function ReframersNoted() {
    console.log('Transition')
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,

      };
  return (
    <motion.div>
    
    <motion.div className="ReframersNoted" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="ReframersNoted-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Noted-Progress-Bar'>
      <div className='Noted-Progress-Indicator'></div>
      <div className='Noted-Progress-Indicator-Gradient'></div>
        <div className='Noted-Name-Icon-Div'>
        
            <img className='Noted-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Noted-Flag-Icon-Div'>
        
            <img className='Noted-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Noted-Pin-Icon-Div'>
        
            <img className='Noted-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Noted-Brain-Icon-Div'>
        
            <img className='Noted-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Noted-Check-Icon-Div'>
        
            <img className='Noted-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
      <p className='Reframers-Noted-Text'>
      Reframers noted a <span>70%</span> decrease in their drinking after working with us. 
      </p>
 
    
    <div className='Noted-Anim-Div'>
    
      <Lottie options = {defaultOptions}  className= "Chart-Lottie-Anim">
      </Lottie>
      </div>

   
    <Link to ='/transition' replace= {true}>
    
      <motion.div type='button' className='Noted-Ready-Button'   >
      <a className='Noted-Ready-Text'>
            Next
          </a>
      </motion.div>
      
   

    </Link>
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default ReframersNoted;
