import './HealthierDrinking.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Flag-Icon.jpg';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="HealthierDrinking" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="HealthierDrinking-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='healthy-Progress-Bar'>
      <div className='healthy-Progress-Indicator'></div>
        <div className='healthy-Name-Icon-Div'>
        
            <img className='healthy-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='healthy-Flag-Icon-Div'>
        
            <img className='healthy-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='healthy-Pin-Icon-Div'>
        
            <img className='healthy-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='healthy-Brain-Icon-Div'>
        
            <img className='healthy-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='healthy-Check-Icon-Div'>
        
            <img className='healthy-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
      <a className='Nice-Job'>
      Nice job, {global.name}! 
      </a>
      <a className='Healthier-Drinking-Text'>
      Next, we’ll learn about your goals for healthier drinking</a>

     
      
     
      
     
      <Link to ='/transition' replace= {true}>
    
      <motion.div type='button' className='healthy-Ready-Button'   >
      <a className='Healthy-Ready-Text'>
            Next
          </a>
      </motion.div>
      
   

    </Link>
      
    
      
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;
