import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Helmet} from "react-helmet";
import App from './App';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import { Navigate, useNavigate } from "react-router-dom";
import  { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Custom from './CustomPlanTransitionScreen'
import {AnimatePresence, motion} from 'framer-motion'
import Initial from './InitialOnboardingScreen'
import Map from './MapOnboardingScreen'
import Research from './ResearchAnalyticsScreen'
import Doctor from './DoctorScreen'
import Name from './NameScreen'
import AlcoholReduction from'./AlcoholReductionScreen.js';
import HowManyDrinks from './HowManyDrinksScreens.js';
import HearAbout from './HearAboutScreen.js';
import IdentifyScreen from './IdentifyScreen.js';
import Age from './AgeRangeScreen.js';
import HealthierDrinking from './HealthierDrinkingScreen.js';
import WhyChange from './WhyChangeScreen.js';
import HelpYouScreen from './HelpYouScreen.js';
import AllInOne from './AllInOneScreen.js';
import NextQuestions from './NextQuestionsScreen.js';
import TypicalWeek from './TypicalWeekScreen.js';
import DrinkingChart from './DrinkingChartScreen.js';
import TypeDrink from './TypeDrinkScreen.js';
import TriedBefore from './TriedBeforeScreen.js';
import AlcoholInterfered from './AlcoholInterferedScreen.js';
import DrinkingReasons from './DrinkingReasonsScreen.js';
import ToPersonalizedPlan from './ToPersonalizedPlanScreen.js';
import KeepThatInMind from './KeepThatInMindScreen.js';
import GotYourBack from './GotYourBackScreen.js';
import RememberReasons from './RememberReasonsScreen.js';
import ReframersNoted from './ReframersNotedScreen.js';
import SignUp from './SignUpScreen.js';
import ConfirmationCode from './ConfirmationCodeScreen.js';
import YoullSave from './YoullSaveScreen.js';
import VennDiagram from './VennDiagramScreen.js';
import CustomPlan from './CusotmPlanScreen.js';
import AllDone from './AllDoneScreen.js';
import { useSearchParams } from "react-router-dom";


import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
  {/* <Helmet><title>Reframe</title></Helmet> */}
  <AnimatePresence  exitBeforeEnter>
  <switch>
  <Router> 
    <Routes>
    <Route path='/' exact element = {<Name/>}></Route>
    <Route path= '/identity' exact element = {<IdentifyScreen/>}></Route>
    <Route path='/age' exact element = {<Age/>}></Route>
    <Route path='/typicalWeek' exact element = {<TypicalWeek/>}></Route>

    <Route path='/alcoholReduction' exact element = {<AlcoholReduction/>}></Route>
    <Route path='/signup' exact element = {<SignUp/>}></Route>
    <Route path='/ConfirmationCode' exact element = {<ConfirmationCode/>}></Route>
    <Route path = '/CustomPlan' exact element = {<CustomPlan/>}></Route>
    <Route path = '/AllDone' exact element = {<AllDone/>}></Route>

    </Routes>
  </Router>
  </switch>
  </AnimatePresence>
  </>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();