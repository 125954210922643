import './WhyChange.css';
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react';
import BackArrow from './BackArrow.svg';
import Person from './Person-Icon.svg';
import Flag from './Completed-Flag-Icon.png';
import Pin from './Pin-Icon.svg';
import Brain from './Brain-Icon.svg';
import Check from './Check-Icon.svg';
import { Navigate, useNavigate } from "react-router-dom";



import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from 'react-router-dom';
import InitialOnboardingScreen from './InitialOnboardingScreen';
  
function DoctorScreen() {
  const navigate = useNavigate();
    function toNextPage (tosave){
      console.log('To Next page', tosave);
      global.whyChange = tosave;
      navigate('/name')
      
    }
    console.log('Transition')
  return (
    <motion.div>
    
    <motion.div className="WhyChange" initial ={{opacity: 0}} animate = {{opacity:1, duration: 50}} transition = {{duration: 2}}>

      <header className="WhyChange-header">
      
        
      </header>
      <img className='Back-Arrow' src = {BackArrow}>
      </img>
      <div className='Why-Progress-Bar'>
      <div className='Why-Progress-Indicator'></div>
      <div className='Why-Progress-Indicator-Gradient'></div>
        <div className='Why-Name-Icon-Div'>
        
            <img className='Why-Name-Icon'
            src  = {Person}>
            </img>
        </div>
        <div className='Why-Flag-Icon-Div'>
        
            <img className='Why-Flag-Icon'
            src  = {Flag}>
            </img>
        </div>
        <div className='Why-Pin-Icon-Div'>
        
            <img className='Why-Pin-Icon'
            src  = {Pin}>
            </img>
        </div>
        <div className='Why-Brain-Icon-Div'>
        
            <img className='Why-Brain-Icon'
            src  = {Brain}>
            </img>
        </div>
        <div className='Why-Check-Icon-Div'>
        
            <img className='Why-Check-Icon'
            src  = {Check}>
            </img>
        </div>


      </div>
    
      
      <div className='Why-Main-Div'>
      <a className='Why-Change-Text'>
      {global.name}, why do you want to change your drinking habits?
      </a>
      <a className='Lets-Start'>
      Let’s start with one...
      </a>
      <a className='Choose-Later'>
      You can choose more later
      </a>
      <div className='Options-Div'>
      <div className='Health-Div' onClick={() => toNextPage("Health")}>
        <a className='Health-Text'>
        Improve my health and wellness
        </a>
      </div>
      <div className='Sleep-Div'  onClick={() => toNextPage("Sleep")}>
        <a className='Sleep-Text'>
        Get better sleep      
     </a>
      </div>
      <div className='Effects-Div' onClick={() => toNextPage("NegativeEffects")}>
        <a className='Effects-Text' >
        Avoid negative external effects (legal, social)
                </a>
      </div>
      <div className='Relationships-Div' onClick={() => toNextPage("Relationships")}>
        <a className='Relationships-Text'>
        Improve relationships with others
        </a>
      </div>
      <div className='Feel-Better-Div' onClick={() => toNextPage("FeelBetter")}>
        <a className='Feel-Better-Text'>
        I’m not sure, just to feel better        </a>
      </div>
      <div className='Money-Div' onClick={() => toNextPage("SaveMoney")}>
      <a className='Money-Text'>
      Save money
      </a>
    </div>
    </div>
      </div>
     
    </motion.div>
    </motion.div>
  );
}

export default DoctorScreen;
