import './InitialOnboardingScreen.css';
import { Navigate, useNavigate } from "react-router-dom";
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { motion} from 'framer-motion'
import Custom from './CustomPlanTransitionScreen'
import Lottie from 'react-lottie'
import animationData from './Brain_Animation.mp4.lottie.json'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  withRouter,
  Link
} from 'react-router-dom';


 

function InitialOnboardingScreen() {
  console.log('Test')

  const navigate = useNavigate();

    const handleClick = () => {
      setTimeout(() =>{
        navigate("/map");
      }, 2000);
      
    }
    
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  return (
    
      <div className="App">
     
    <header className="App-header" >

      
    </header>
    
   <div className='Brain_Anim_Div'>
   
      <Lottie options = {defaultOptions}  >
      </Lottie>
    </div>

    <p className='Reduction-Platform'>
    The world’s most comprehensive alcohol reduction platform
    </p>
    <motion.div className='Random'
    initial = {{ opacity: 1}}
    animate = {{opacity:1}}
    exit ={{ opacity:1, duration: 1, scale: 1}}
    ></motion.div>
    <motion.div className='Transition-Section'
    initial = {{ opacity: 0}}
    animate = {{opacity:0}}
    exit ={{ opacity:1, scale: 5, duration: 2}}
    ></motion.div>
   
      <motion.div type='button' className='Next-Button' onClick  = { ()=> {handleClick()}}  >
      <a className='Next-Text'>
            Start building my custom plan &#8594;
          </a>
      </motion.div>
      
   

  

  
    
    </div>
    
  );
}
 
export default InitialOnboardingScreen;
